import { useRouter } from 'next/router';

import { Modal } from './Modal';
import { useEffect } from 'react';
import { reportAnalyticsEvent, reportJuicyAds } from 'utils/analytics';

type Props = {
  type: 'success' | 'cancel';
  onClose: () => void;
};

export const AfterCreditsPurchaseModal = ({ type, onClose }: Props) => {
  const { push, query } = useRouter();
  const isSuccess = type === 'success';

  useEffect(() => {
    const user_id = query.user_id;
    const amount = query.amount;
    const package_id = query.package_id;

    reportAnalyticsEvent(`Checkout ${isSuccess ? 'Completed' : 'Failed'}`, { user_id, amount, package_id });

    if (isSuccess) {
      const date = new Date();
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months start at 0
      const dd = String(date.getDate()).padStart(2, '0');
      const td = `${yyyy}${mm}${dd}`; // Format: YYYYMMDD

      reportAnalyticsEvent('purchase_credits', {
        currency: 'USD',
        value: Number(amount),
        transaction_id: `T_${user_id}_${td}`,
        items: [
          {
            item_id: `C_${package_id}`,
            item_name: 'Credits'
          }
        ]
      });

      if (amount) reportJuicyAds(+amount / 100);
    }
  }, []);

  const closeAndRefetchUser = async () => {
    if (isSuccess) push('/chat');

    onClose();
  };

  return (
    <Modal onClose={closeAndRefetchUser}>
      <p className="mb-7">
        {isSuccess ? (
          'Thank you for purchasing Crush Credits! We hope you enjoy all the benefits as a VIP! Your credits will be loaded into your account shortly.'
        ) : (
          <>
            Sorry credits purchase failed and your card was not charged. Please try again or contact{' '}
            <a href="mailto:support@crush.to" className="hover:text-brand text-orange-400 text-sm md:text-base">
              support@crush.to
            </a>{' '}
            for assistance.
          </>
        )}
      </p>

      <button type="button" className="btn contained primary w-full" onClick={closeAndRefetchUser}>
        Close
      </button>
    </Modal>
  );
};
